import CronParser from "cron-parser";
import { NOT_SPECIFIED } from "@/utils/consts";
import cron_human_readable from "cronstrue";
import dayjs from "dayjs";

export const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const TIME_UNITS = {
  MINUTES: "minute",
  HOURS: "hour",
  DAYS: "day",
};

export const TIME_UNITS_FORMAT = {
  HOURS_MINUTES: "HH:mm",
  MINUTES: "YYYY-MM-DD HH:mm:ss",
  HOURS: "YYYY-MM-DD HH",
  DAYS: "YYYY-MM-DD",
};

export const CRON_STRING_TYPE = {
  EMPTY: "* * * * *",
  SIMPLE: "simple",
  CRON: "cron",
};

export const TIME_DIRECTION = Object({
  FUTURE: "FUTURE",
  PAST: "PAST",
  PRESENT: "PRESENT",
});

/**
 * Get number of days in a month
 * @param {string} month - month of which we want to know the number of days
 */
export function get_month_days(month) {
  const date = dayjs().month(month - 1);
  return { name: date.format("MMMM"), days: date.daysInMonth() };
}
/**
 * Convert cron expression to string
 * @param {string} cron_expression - cron expression
 * @param {string} type - number of units
 */
export function cron_to_to_yyyy_mm_dd_hh_MM(
  cron_expression,
  type = TIME_DIRECTION.PRESENT
) {
  if (cron_expression) {
    let result = CronParser.parseExpression(cron_expression, {
      tz: "Europe/Prague",
    });
    if (type === TIME_DIRECTION.FUTURE) {
      result = result.next();
    } else if (type === TIME_DIRECTION.PAST) {
      result = result.prev();
    }
    return dayjs(result.toString()).format(TIME_UNITS_FORMAT.MINUTES);
  } else {
    return NOT_SPECIFIED;
  }
}

/**
 * Convert cron expression to human-readable string
 * @param {string} cron_expression - cron expression to convert
 */
export function cron_to_human_readable(cron_expression) {
  if (cron_expression) {
    return cron_human_readable.toString(cron_expression, {
      use24HourTimeFormat: true,
      dayOfWeekStartIndexZero: true,
      verbose: true,
    });
  } else {
    return NOT_SPECIFIED;
  }
}
