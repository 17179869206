// import Vue from "vue";
import axios from "axios";
import { AuthStore } from "@/stores/auth";
import { ROUTES } from "@/utils/requests/routes";

const MAX_ITEMS_PER_PAGE = 2000;

export function process_request(method, url, data) {
  const auth_store = AuthStore();
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (auth_store.token) {
    headers["Authorization"] = `Token ${auth_store.token}`;
  }
  return axios({
    method: method,
    url: `${ROUTES.BASE_URL}/${url}`,
    data: data,
    headers,
  });
}

export function parse_error_message(error) {
  if ("data" in error.response) {
    return `[${error.response?.status} ${error.response?.statusText}] ${error.response.data?.message}`;
  } else {
    return error.response.statusText;
  }
}

export function pagination_to_limits_offsets(page, items_per_page) {
  return `limit=${
    items_per_page != -1 ? items_per_page : MAX_ITEMS_PER_PAGE
  }&offset=${(page - 1) * items_per_page}`;
}

export function filter_to_search(search_options) {
  let result = [];
  for (let key in search_options) {
    if (search_options[key] !== "") {
      result.push(`${key}=${search_options[key]}`);
    }
  }
  return result.length > 0 ? `&${result.join("&")}` : "";
}

export function sort_to_ordering(sort_by, sort_desc) {
  if (sort_by.length) {
    return `&ordering=${sort_by
      .map((item, index) => (sort_desc[index] ? "-" : "") + item)
      .join(",")}`;
  } else {
    return "";
  }
}

export function query_parameters(params) {
  if (params.length > 0) {
    let result = "";
    for (let param of params) {
      result += `&${param.name}=${param.value}`;
    }
    return `?${result.substring(1)}`;
  } else {
    return "/";
  }
}
