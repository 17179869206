<template>
  <div>
    <v-snackbar
      v-model="show_pop_up"
      :timeout="10000"
      dense
      outlined
      text
      :width="400"
      :min-height="80"
      :color="message.type === STATUS.SUCCESS ? 'success' : 'error'"
      :bottom="true"
      :right="true"
      v-for="(message, index) in alert_messages"
      :key="`alert_message_pop_up_${index}`"
    >
      <div class="d-flex align-center">
        <v-icon
          class="mr-4"
          color="success"
          v-if="message.type === STATUS.SUCCESS"
          >mdi-check-circle</v-icon
        >
        <v-icon class="mr-2" color="error" v-else>mdi-close-circle</v-icon>
        <span class="text-subtitle-1">{{ message.alert_message }}</span>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="show_pop_up = false"
          :color="message.type === STATUS.SUCCESS ? 'success' : 'error'"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { AlertsStore } from "@/stores/alerts";
import { STATUS } from "@/utils/requests/routes";

export default {
  name: "AlertPopUpMessage",
  setup() {
    const alerts_store = AlertsStore();
    return { alerts_store };
  },
  props: {
    number_of_messages: { type: Number, required: true },
  },
  data() {
    return {
      STATUS,
      show_pop_up: false,
    };
  },
  watch: {
    alert_messages() {
      this.show_pop_up = this.visible;
    },
    visible() {
      this.show_pop_up = this.visible;
    },
  },

  computed: {
    alert_messages() {
      return this.number_of_messages
        ? this.alerts_store.get_alerts.slice(-this.number_of_messages)
        : [];
    },
    visible() {
      return this.alerts_store.messages_visibility;
    },
  },
};
</script>

<style scoped></style>
