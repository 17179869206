import { ROUTES } from "@/utils/requests/routes";
import Router from "vue-router";
import { check_auth, set_alert } from "@/middlewares";

let router = new Router({
  mode: "history",
  base: ROUTES.HOMEPAGE,
  async scrollBehavior(to) {
    // scroll up on router next
    if (to.hash) {
      setTimeout(function () {
        document
          .querySelector(to.hash)
          .scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: `/${ROUTES.LOGIN}`,
      name: ROUTES.LOGIN,
      component: () => import("@/views/login/LoginView.vue"),
    },
    {
      path: ROUTES.HOMEPAGE,
      name: "/",
      component: () => import("@/views/HomeView.vue"),
      children: [
        {
          path: `/${ROUTES.DASHBOARD}`,
          name: ROUTES.DASHBOARD,
          component: () => import("@/views/dashboard/DashboardView.vue"),
        },
        {
          path: `/${ROUTES.USERS}`,
          name: ROUTES.USERS,
          component: () => import("@/views/users/UsersView.vue"),
        },
        {
          path: `/${ROUTES.USERS}/:id`,
          name: "user",
          component: () => import("@/views/users/UserView.vue"),
        },
        {
          path: `/${ROUTES.CLIENTS}`,
          name: ROUTES.CLIENTS,
          component: () => import("@/views/clients/ClientsView.vue"),
        },
        {
          path: `/${ROUTES.CLIENTS}/:id`,
          name: "client",
          alias: `/${ROUTES.CLIENTS}/:id/${ROUTES.ACCOUNTS}`,
          component: () => import("@/views/clients/ClientView.vue"),
        },
        {
          path: `/${ROUTES.CLIENTS}/:client_id/${ROUTES.ACCOUNTS}/:id`,
          name: "account",
          component: () => import("@/views/accounts/AccountView.vue"),
        },
        {
          path: `/${ROUTES.SCRIPTS}`,
          name: ROUTES.SCRIPTS,
          component: () => import("@/views/scripts/ScriptsView.vue"),
        },
        {
          path: `/${ROUTES.SCRIPTS}/:id`,
          name: "script",
          component: () => import("@/views/scripts/ScriptView.vue"),
        },
        {
          path: `/${ROUTES.JOBS}`,
          name: "jobs",
          component: () => import("@/views/jobs/JobsView.vue"),
        },
        {
          path: `/${ROUTES.JOBS}/:id`,
          name: "job",
          component: () => import("@/views/jobs/JobView.vue"),
        },
        {
          path: `/${ROUTES.PLATFORMS}`,
          name: ROUTES.PLATFORMS,
          component: () => import("@/views/plat-forms/PlatformsView.vue"),
        },
        {
          path: `/${ROUTES.PLATFORMS}/:id`,
          name: "platform",
          component: () => import("@/views/plat-forms/PlatformView.vue"),
        },
        {
          path: `/${ROUTES.TEAMS}`,
          name: ROUTES.TEAMS,
          component: () => import("@/views/teams/TeamsView.vue"),
        },
        {
          path: `/${ROUTES.TEAMS}/:id`,
          name: "team",
          component: () => import("@/views/teams/TeamView.vue"),
        },
        {
          path: `/${ROUTES.SECRETS}`,
          name: ROUTES.SECRETS,
          component: () => import("@/views/secrets/SecretsView.vue"),
        },
        {
          path: `/${ROUTES.SECRETS}/:id`,
          name: "secret",
          component: () => import("@/views/secrets/SecretView.vue"),
        },
        {
          path: `/${ROUTES.JOBS}/:job_id/${ROUTES.JOB_RUNS}/:id`,
          name: "job-run",
          component: () => import("@/views/jobs/JobRunView.vue"),
        },
        {
          path: `/${ROUTES.ABOUT}`,
          name: ROUTES.ABOUT,
          component: () => import("@/views/about/AboutView.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  let next_url = check_auth(to);
  set_alert();
  next_url ? next(next_url) : next();
});

export default router;
