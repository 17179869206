import { defineStore } from "pinia";
import { process_request } from "@/utils/requests";
import { METHODS, ROUTES } from "@/utils/requests/routes";

export const PlatformsStore = defineStore("platforms", {
  state: () => ({
    headers: [
      // { text: "id", value: "id" },
      { text: "name", value: "name" },
      { text: "badge", value: "badge", sortable: false },
      {
        text: "no. of secrets",
        value: "secrets",
        sort: (a, b) => a.length - b.length,
      },
      { text: "", value: "actions" },
    ],
    platforms: [],
  }),
  getters: {
    get_platform: (state) => {
      return (platform_id) =>
        platform_id
          ? state.platforms.find((platform) => platform.id == platform_id)
          : {};
    },
    get_platform_by_short_name: (state) => {
      return (platform_short_name) =>
        platform_short_name
          ? state.platforms.find(
              (platform) => platform.short_name === platform_short_name
            )
          : {};
    },
  },
  actions: {
    async fetch_platforms() {
      let response = await process_request(
        METHODS.GET,
        `${ROUTES.API}/${ROUTES.PLATFORMS}/`
      );
      this.platforms = response.data;
    },
  },
});
