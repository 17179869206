import { defineStore } from "pinia";
import { UsersStore } from "@/stores/users";
import { process_request, parse_error_message } from "@/utils/requests";
import { METHODS, ROUTES, STATUS } from "@/utils/requests/routes";
import { AlertsStore } from "@/stores/alerts";
import { parse_team } from "@/utils/teams";
import { AuthStore } from "@/stores/auth";
import { ROLES } from "@/utils/permissions";

export let TeamsStore = defineStore("teams", {
  state: () => ({
    headers: [
      // {text: 'id', value: 'id'},
      { text: "name", value: "name" },
      {
        text: "leaders",
        value: "team_leaders",
        sort: (a, b) => a.length - b.length,
      },
      { text: "members", value: "members" },
      { text: "", value: "actions", sortable: false },
    ],
    teams: [],
  }),
  getters: {
    get_team: (state) => {
      return (team_id) => state.teams.find((team) => team.id == team_id);
    },
    find_teamless_users: (state) => {
      const users_store = UsersStore();
      const all_team_users = state.teams
        .map((team) => team.members.map((member) => member.user))
        .flat();
      return users_store.users.filter(
        (user) => !all_team_users.includes(user.id)
      );
    },
    get_team_member_users: (state) => {
      return (team_id) => {
        if (team_id == null) {
          return [];
        }
        const users_store = UsersStore();
        const team = state.get_team(team_id);
        return team.members.map((member) => users_store.get_user(member.user));
      };
    },
    get_team_leader_users: (state) => {
      return (team_id) => {
        if (team_id == null) {
          return [];
        }
        const users_store = UsersStore();
        const team = state.get_team(team_id);
        return team.leaders.map((leader) => users_store.get_user(leader));
      };
    },
    is_user_team_leader_of_given_team: (state) => {
      return (team_id, user_id) =>
        state.get_team_leader_users(team_id).find((leader) => {
          return leader.id.toString() === user_id;
        }) !== null;
    },
    is_user_team_member: (state) => {
      return (team_id, user_id) =>
        state.get_team_member_users(team_id).includes(user_id);
    },
    get_teams_acording_to_role: (state) => {
      return (user) =>
        user.is_team_leader ? [state.get_team(user.team)] : state.teams;
    },
  },
  actions: {
    async fetch_teams() {
      let response = await process_request(
        METHODS.GET,
        `${ROUTES.API}/${ROUTES.TEAMS}/`
      );
      this.teams = response.data.map((team) => parse_team(team));
    },
    add_member(user, team) {
      let user_team_record = { user: user.id, is_leader: false };
      this.get_team(team).members.push(user_team_record);
    },
    delete_member(user) {
      let user_team = this.get_team(user.team);
      user_team.members = user_team.members.filter(
        (member) => member.user !== user.id
      );
    },
    async add_team(data) {
      const alerts_store = AlertsStore();
      const auth_store = AuthStore();
      try {
        let response = await process_request(
          METHODS.POST,
          `${ROUTES.API}/${ROUTES.TEAMS}/`,
          data
        );

        const team = response.data["payload"];
        let parsed_team = parse_team(team);
        // update local state: add team to teams
        this.teams.push(parsed_team);
        // find the implied team members and update their team status
        this.add_team_to_users(team);

        // current user becomes team leader, update the role
        if (parsed_team.leaders.includes(auth_store.user.id)) {
          auth_store.user.roles.push(ROLES.TEAM_LEADER);
          auth_store.user.team = team.id;
        }

        // add role to team leaders
        this.add_role_to_users(parsed_team.leaders, ROLES.TEAM_LEADER);

        // add role to team members
        this.add_role_to_users(
          parsed_team.members
            .filter((obj) => obj.is_leader === false)
            .map((obj) => obj.user),
          ROLES.TEAM_MEMBER
        );

        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
      }
    },
    async edit_team(data) {
      const alerts_store = AlertsStore();
      const auth_store = AuthStore();
      try {
        let response = await process_request(
          METHODS.PATCH,
          `${ROUTES.API}/${ROUTES.TEAMS}/${data.id}/`,
          data
        );
        const current_entry_team = this.get_team(data.id);

        const team = response.data["payload"];
        let parsed_team = parse_team(team);

        // start of updating local state
        // is the user editing their own team?
        if (auth_store.user.team === team.id) {
          // is the user currently a team leader of this team?
          if (auth_store.user.roles.includes(ROLES.TEAM_LEADER)) {
            // if so, is he to remain a team leader?
            if (!parsed_team.leaders.includes(auth_store.user.id)) {
              auth_store.user.roles = auth_store.user.roles.filter(
                (role) => role !== ROLES.TEAM_LEADER
              );
            }
          } else {
            // the user is not a team leader now, is he to become one?
            if (parsed_team.leaders.includes(auth_store.user.id)) {
              auth_store.user.roles.push(ROLES.TEAM_LEADER);
            }
          }
        }
        // delete role from current team leaders
        this.delete_role_from_users(
          current_entry_team.leaders,
          ROLES.TEAM_LEADER
        );

        // delete role from current team members
        this.delete_role_from_users(
          current_entry_team.members.map((obj) => obj.user),
          ROLES.TEAM_MEMBER
        );

        // add role to new team leaders
        this.add_role_to_users(parsed_team.leaders, ROLES.TEAM_LEADER);

        // add role to new team members
        this.add_role_to_users(
          parsed_team.members
            .filter((obj) => obj.is_leader === false)
            .map((obj) => obj.user),
          ROLES.TEAM_MEMBER
        );

        // find the users of the team and delete it
        this.delete_team_from_users(current_entry_team);
        // delete the team itself
        this.teams = this.teams.filter((team) => team.id !== data.id);
        // add team to teams
        this.teams.push(parsed_team);
        // find the implied team members and update their team status
        this.add_team_to_users(team);
        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
      }
    },
    async delete_team(team) {
      const alerts_store = AlertsStore();
      const auth_store = AuthStore();
      try {
        let response = await process_request(
          METHODS.DELETE,
          `${ROUTES.API}/${ROUTES.TEAMS}/${team.id}/`
        );

        // start of updating local state
        // is the user deleting their own team? delete the team leader permission if so
        if (auth_store.user.team === team.id) {
          auth_store.user.roles = auth_store.user.roles.filter(
            (role) => role !== ROLES.TEAM_LEADER
          );
          auth_store.user.team = null;
        }

        // delete role from team leaders
        this.delete_role_from_users(team.leaders, ROLES.TEAM_LEADER);

        // delete role from team members
        this.delete_role_from_users(
          team.members.map((obj) => obj.user),
          ROLES.TEAM_MEMBER
        );

        // find the users of the team and delete it
        this.delete_team_from_users(team);
        // delete the team itself
        this.teams = this.teams.filter(
          (current_team) => current_team.id !== team.id
        );
        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
      }
    },
    add_team_to_users(team) {
      const user_store = UsersStore();
      team.members.forEach(
        (member) => (user_store.get_user(member.user).team = team.id)
      );
    },
    delete_team_from_users(team) {
      const user_store = UsersStore();
      team.members.forEach(
        (member) => (user_store.get_user(member.user).team = null)
      );
    },
    add_role_to_users(users_ids, role) {
      const users_store = UsersStore();
      for (let user_id of users_ids) {
        users_store.get_user(user_id).roles.push(role);
      }
    },
    delete_role_from_users(users_ids, role) {
      const users_store = UsersStore();
      for (let user_id of users_ids) {
        let user = users_store.get_user(user_id);
        user.roles = user.roles.filter((obj) => obj !== role);
      }
    },
  },
});
