import { AuthStore } from "@/stores/auth";
import { AlertsStore } from "@/stores/alerts";
import { ROUTES } from "@/utils/requests/routes";

export function check_auth(to) {
  const auth_store = AuthStore();
  if (to.path !== `/${ROUTES.LOGIN}` && !auth_store.is_signed_in) {
    auth_store.log_in_from_cookie();
    if (!auth_store.user.is_user_active) {
      auth_store.log_out();
      return `/${ROUTES.LOGIN}`;
    }
    if (!auth_store.is_signed_in) {
      return `/${ROUTES.LOGIN}`;
    }
  }
}

export function set_alert() {
  const alerts_store = AlertsStore();
  if (alerts_store.keep_visibility) {
    alerts_store.set_retain_visibility(false);
    alerts_store.set_messages_visibility(true);
  } else {
    alerts_store.set_messages_visibility(false);
  }
}
