import Vue from "vue";
import App from "@/App.vue";

import vuetify from "@/plugins/vuetify";

import Router from "vue-router";
import router from "./router";

import { createPinia, PiniaVuePlugin } from "pinia";

import acl from "@/plugins/vue_browser_acl";

import VueCookies from "vue-cookies";

import AlertMessage from "@/common/alerts/AlertMessage";
import AlertPopUpMessage from "@/common/alerts/AlertPopUpMessage";
import BaseDialog from "@/common/forms/BaseDialog";

import axios from "axios";
import VueAxios from "vue-axios";

import DatetimePicker from "vuetify-datetime-picker";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(Router);
Vue.use(VueCookies, { expire: "3d" });

Vue.use(DatetimePicker);

Vue.config.productionTip = false;

Vue.component("alert-message", AlertMessage);
Vue.component("alert-pop-up-message", AlertPopUpMessage);
Vue.component("base-dialog", BaseDialog);

Vue.use(VueAxios, axios);

const vueInstance = new Vue({
  vuetify,
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");

export default {
  vueInstance,
  acl,
};
