import Vue from "vue";
import Acl from "vue-browser-acl";
import { AuthStore } from "@/stores/auth";
import {
  is_admin,
  is_admin_leader,
  is_admin_leader_user,
  RULES,
} from "@/utils/permissions";

export const auth_user = () => {
  return AuthStore();
};

Vue.use(Acl, auth_user, (acl) => {
  acl.rule(RULES.ALLOW_ALL, true);
  // dashboard
  acl.rule(RULES.DASHBOARD_VIEW, true);
  // user
  acl.rule(RULES.USERS_VIEW, true);
  acl.rule(RULES.USER_EDIT, {}, is_admin_leader_user);
  // team
  acl.rule(RULES.TEAMS_VIEW, true);
  acl.rule(RULES.TEAM_ADD, true);
  acl.rule(RULES.TEAM_EDIT, [], is_admin_leader);
  acl.rule(RULES.TEAM_DELETE, [], is_admin_leader);
  acl.rule(RULES.TEAM_ADD_USER, true);
  acl.rule(RULES.TEAM_DELETE_USER, {}, is_admin_leader_user);
  acl.rule(RULES.TEAM_VIEW_JOBS, [], is_admin_leader);
  // client
  acl.rule(RULES.CLIENTS_VIEW, true);
  acl.rule(RULES.CLIENT_ADD, true);
  acl.rule(RULES.CLIENT_EDIT, true);
  acl.rule(RULES.CLIENT_DELETE, [], is_admin_leader);
  // account
  acl.rule(RULES.ACCOUNT_ADD, true);
  acl.rule(RULES.ACCOUNT_EDIT, true);
  acl.rule(RULES.ACCOUNT_DELETE, [], is_admin_leader);
  // job
  acl.rule(RULES.JOBS_VIEW, true);
  acl.rule(RULES.JOB_ADD, true);
  acl.rule(RULES.JOB_EDIT, {}, is_admin_leader_user);
  acl.rule(RULES.JOB_DELETE, {}, is_admin_leader_user);
  acl.rule(RULES.JOB_ADD_SUBSCRIPTION, {}, is_admin_leader_user);
  acl.rule(RULES.JOB_DELETE_SUBSCRIPTION, {}, is_admin_leader_user);
  acl.rule(RULES.JOB_EDIT_SUBSCRIPTION, {}, is_admin_leader_user);
  // job
  acl.rule(RULES.TASK_ADD, true);
  acl.rule(RULES.TASK_EDIT, {}, is_admin_leader_user);
  acl.rule(RULES.TASK_DELETE, {}, is_admin_leader_user);
  // platform
  acl.rule(RULES.PLATFORMS_VIEW, true);
  acl.rule(RULES.PLATFORM_ADD, is_admin);
  acl.rule(RULES.PLATFORM_EDIT, is_admin);
  acl.rule(RULES.PLATFORM_DELETE, is_admin);
  // secret
  acl.rule(RULES.SECRETS_VIEW, true);
  acl.rule(RULES.SECRET_ADD, true);
  acl.rule(RULES.SECRET_EDIT, {}, is_admin_leader_user);
  acl.rule(RULES.SECRET_DELETE, {}, is_admin_leader_user);
  // script
  acl.rule(RULES.SCRIPTS_VIEW, true);
  acl.rule(RULES.SCRIPT_ADD, is_admin);
  acl.rule(RULES.SCRIPT_EDIT, is_admin);
  acl.rule(RULES.SCRIPT_DELETE, is_admin);
});

export default Acl;
