<template>
  <v-app>
    <div>
      <router-view :key="$router.currentRoute.params.id"></router-view>
    </div>
  </v-app>
</template>

<script>
import { PlatformsStore } from "@/stores/plat-forms";
import { UsersStore } from "@/stores/users";
import { ScriptsStore } from "@/stores/scripts";
import { SecretsStore } from "@/stores/secrets";
import { AuthStore } from "@/stores/auth";
import { TeamsStore } from "@/stores/teams";

import { storeToRefs } from "pinia";

export default {
  name: "App",
  setup() {
    const platforms_store = PlatformsStore();
    const users_store = UsersStore();
    const scripts_store = ScriptsStore();
    const secrets_store = SecretsStore();
    const auth_store = AuthStore();
    const teams_store = TeamsStore();
    const { is_signed_in } = storeToRefs(auth_store);
    return {
      platforms_store,
      users_store,
      scripts_store,
      secrets_store,
      auth_store,
      teams_store,
      is_signed_in,
    };
  },
  watch: {
    async is_signed_in(new_value) {
      if (new_value) {
        await this.initialize();
      }
    },
  },
  async created() {
    if (this.is_signed_in) {
      await this.initialize();
    }
  },
  methods: {
    async initialize() {
      await this.platforms_store.fetch_platforms(false);
      await this.users_store.fetch_users(false);
      await this.scripts_store.fetch_scripts(false);
      await this.secrets_store.fetch_secrets(false);
      await this.teams_store.fetch_teams(false);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&family=JetBrains+Mono&display=swap");
#app {
  font-family: "Roboto", sans-serif !important;
}
a {
  text-decoration: none !important;
}
.theme--dark.v-sheet--outlined {
  border: thin solid #392b38 !important;
}
.v-list {
  background-color: var(--v-background_secondary-base) !important;
}
.jetbrains-font {
  font-family: "JetBrains Mono", monospace !important;
}
.fira-code-font {
  font-family: "Fira Code", monospace;
}
.font-size-report-body {
  font-size: 12px;
}
.font-size-report-title {
  font-size: 13px;
}
</style>
