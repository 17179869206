import Vue from "vue";
import router from "@/router";
import { defineStore } from "pinia";
import { UsersStore } from "@/stores/users";
import { AlertsStore } from "../alerts";

import { process_request, parse_error_message } from "@/utils/requests";
import { ROUTES, METHODS, STATUS } from "@/utils/requests/routes";
import { ROLES } from "@/utils/permissions";

export const LOGOS_COOKIE = "logos-login";

export const AuthStore = defineStore("auth", {
  state: () => ({
    user: {
      id: "",
      name: "",
      first_name: "",
      last_name: "",
      email: "",
      picture: "",
      team: "",
      roles: [],
    },
    is_signed_in: false,
    token: null,
    client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
    is_login_being_processed: false,
  }),
  getters: {
    is_admin() {
      return this.user.roles.includes(ROLES.ADMIN);
    },
    is_team_leader() {
      return this.user.roles.includes(ROLES.TEAM_LEADER);
    },
  },
  actions: {
    initialize_google_login(button_parent_element) {
      window.google.accounts.id.initialize({
        client_id: this.client_id,
        hd: "*", // this ensures that a user signing in must be a part of a g-suite organisation (any organisation, the check for the whitelist is performed on BE)
        callback: async (response) => {
          await this.log_in(response);
        },
      });
      window.google.accounts.id.renderButton(button_parent_element, {
        shape: "pill",
        width: "300px",
        logo_alignment: "center",
      });
    },
    async log_out() {
      this.user.id = null;
      this.user.name = null;
      this.user.first_name = null;
      this.user.last_name = null;
      this.user.email = null;
      this.user.google_account_picture_url = null;
      this.user.roles = [];
      this.is_signed_in = false;
      Vue.$cookies.remove(LOGOS_COOKIE);
      await router.push(`/${ROUTES.LOGIN}`);
    },
    async log_in(google_response) {
      this.is_login_being_processed = true;
      const alert_store = AlertsStore();
      try {
        this.token = null;
        let response = await process_request(METHODS.POST, `${ROUTES.AUTH}/`, {
          token: google_response.credential,
        });
        const user_store = UsersStore();
        this.user = user_store.parse_user(response.data.user);
        this.token = response.data.token;
        this.is_signed_in = true;
        Vue.$cookies.set(
          LOGOS_COOKIE,
          { user: this.user, token: this.token },
          "8h"
        );
        await router.push(`/${ROUTES.DASHBOARD}`);
      } catch (err) {
        alert_store.add_alert(STATUS.ERROR, parse_error_message(err));
      }
      this.is_login_being_processed = false;
    },
    async log_in_from_cookie() {
      this.is_login_being_processed = true;
      let cookie = Vue.$cookies.get(LOGOS_COOKIE);
      if (cookie !== null) {
        this.user = cookie.user;
        this.token = cookie.token;
        this.is_signed_in = true;
      }
      this.is_login_being_processed = false;
    },
    async check_force_logout(dialog) {
      if (dialog.form_is_user_active == null) {
        return;
      }
      if (dialog.data.id === this.user.id && !dialog.form_is_user_active) {
        await this.log_out();
      }
    },
  },
});
