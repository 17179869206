<template>
  <div>
    <span>
      Do you truly wish to {{ data.text || "delete" }}
      <span>{{ data.item_type }}</span>
    </span>
    <b class="text--primary">
      {{ data.item.name || data.item.id }}
    </b>
    <span v-if="data.from && data.from_type">
      <span> from </span>
      {{ data.from_type }}
      <b class="text--primary"> {{ data.from.name }}? </b>
    </span>
    <span v-else>?</span>
    <span v-if="data.job_delete_warning"> Related jobs will be deleted.</span>
  </div>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    data: { type: Object, required: true },
  },
};
</script>

<style scoped></style>
