import { defineStore } from "pinia";
import { process_request, parse_error_message } from "@/utils/requests";
import { METHODS, ROUTES, STATUS } from "@/utils/requests/routes";
import { AlertsStore } from "@/stores/alerts";
import { TeamsStore } from "@/stores/teams";
import { get_roles } from "@/utils/user";
import { ROLES } from "@/utils/permissions";

export const UsersStore = defineStore("users", {
  state: () => ({
    headers: [
      { text: "name", value: "name" },
      { text: "email", value: "email" },
      {
        text: "team",
        value: "team",
        sort: (a, b) => a.name.localeCompare(b.name),
        width: 150,
      },
      { text: "roles", value: "roles" },
      { text: "", value: "actions", sortable: false },
    ],
    users: [],
  }),
  getters: {
    get_user: (state) => {
      return (user_data) =>
        state.users.find(
          (item) => item.id == user_data || item.email === user_data
        );
    },
    get_profile_picture(state) {
      return (user_id, size) => {
        let user = state.get_user(user_id);
        if (user) {
          return `${user.google_account_picture_url.split("=s")[0]}=s${size}`;
        }
      };
    },
  },
  actions: {
    async fetch_users() {
      let response = await process_request(
        METHODS.GET,
        `${ROUTES.API}/${ROUTES.USERS}/`
      );
      this.users = response.data.map((user) => this.parse_user(user));
      this.users.sort((a, b) => a.name.localeCompare(b.name));
    },
    parse_user(data) {
      return {
        ...data,
        roles: get_roles(data),
        initials:
          data.first_name.substring(0, 1) + data.last_name.substring(0, 1),
      };
    },
    async edit_user(data) {
      const alerts_store = AlertsStore();
      const team_store = TeamsStore();
      try {
        let response = await process_request(
          METHODS.PATCH,
          `${ROUTES.API}/${ROUTES.USERS}/${data.id}/`,
          data
        );
        let user = this.get_user(data.id);
        if (data.team !== user.team) {
          if (user.team) {
            team_store.delete_member(user);
            user.roles = user.roles.filter((obj) => obj !== ROLES.TEAM_MEMBER);
          }
          if (data.team) {
            team_store.add_member(user, data.team);
            user.roles.push(ROLES.TEAM_MEMBER);
          }
          user.team = data.team;
        }

        user.is_user_active = data.is_user_active;

        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
      }
    },
  },
});
