import { defineStore } from "pinia";
import { process_request, parse_error_message } from "@/utils/requests";
import { METHODS, ROUTES, STATUS } from "@/utils/requests/routes";
import { AlertsStore } from "@/stores/alerts";
import { TIME_UNITS_FORMAT } from "@/utils/time";
import dayjs from "dayjs";

export const SecretsStore = defineStore("secrets", {
  state: () => ({
    headers: [
      // {text: 'id', value: 'id'},
      { text: "name", value: "name" },
      {
        text: "author",
        value: "author",
        sort: (a, b) => a.name.localeCompare(b.name),
      },
      {
        text: "platform",
        value: "platform",
        sort: (a, b) => a.name.localeCompare(b.name),
      },
      { text: "created", value: "created", width: 150 },
      { text: "modified", value: "modified", width: 150 },
      { text: "", value: "actions" },
    ],
    secrets: [],
  }),
  getters: {
    get_secret: (state) => {
      return (secret_id) =>
        secret_id ? state.secrets.find((secret) => secret.id == secret_id) : {};
    },
    get_users_secrets: (state) => {
      return (user_id) =>
        state.secrets.filter((secret) => secret.author == user_id);
    },
    get_platform_secrets: (state) => {
      return (platform_id) =>
        platform_id
          ? state.secrets.filter((secret) => secret.platform == platform_id)
          : [];
    },
  },
  actions: {
    async fetch_secret_payload(form) {
      const alerts_store = AlertsStore();
      let secret = this.get_secret(form.id);
      return process_request(
        METHODS.POST,
        `${ROUTES.API}/${ROUTES.SECRETS}/${secret.id}/`,
        secret
      )
        .then((response) => {
          try {
            return JSON.parse(response.data);
          } catch (error) {
            return response.data;
          }
        })
        .catch((error) => {
          alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
          return null;
        });
    },
    async fetch_secrets() {
      let response = await process_request(
        METHODS.GET,
        `${ROUTES.API}/${ROUTES.SECRETS}/`
      );
      this.secrets = response.data.map((secret) => this.parse_secret(secret));
      this.secrets.sort((a, b) => a.name.localeCompare(b.name));
    },
    parse_secret(secret) {
      return {
        ...secret,
        created: dayjs(secret.created).format(TIME_UNITS_FORMAT.MINUTES),
        modified: dayjs(secret.modified).format(TIME_UNITS_FORMAT.MINUTES),
        payload: null,
      };
    },
    async add_secret(form) {
      const alerts_store = AlertsStore();
      try {
        let response = await process_request(
          METHODS.POST,
          `${ROUTES.API}/${ROUTES.SECRETS}/`,
          form
        );
        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
        let parsed_response = this.parse_secret(response.data.payload);
        this.secrets.push(parsed_response);
        return parsed_response;
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
        return null;
      }
    },
    async update_secret(form) {
      let secret = this.get_secret(form.id);
      const alerts_store = AlertsStore();
      try {
        let response = await process_request(
          METHODS.PATCH,
          `${ROUTES.API}/${ROUTES.SECRETS}/${form.id}/`,
          form
        );
        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
        secret.payload = response.data.payload;
        secret.author = form.author;
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
      }
    },
    async delete_secret(secret) {
      const alerts_store = AlertsStore();
      try {
        let response = await process_request(
          METHODS.DELETE,
          `${ROUTES.API}/${ROUTES.SECRETS}/${secret.id}/`
        );
        alerts_store.add_alert(STATUS.SUCCESS, response.data.message);
        this.secrets = this.secrets.filter((item) => item.id !== secret.id);
      } catch (error) {
        alerts_store.add_alert(STATUS.ERROR, parse_error_message(error));
      }
    },
    remove_access_from_secret(access) {
      let secret = this.get_secret(access.secret);
      secret.accesses = secret.accesses.filter(
        (current_access) => current_access.id != access.id
      );
    },
    async validate_name(name) {
      try {
        await process_request(
          METHODS.POST,
          `${ROUTES.API}/${ROUTES.SECRETS}/${ROUTES.NAME_VALIDATE}/`,
          name
        );
        return [];
      } catch (error) {
        return [error.response["data"]];
      }
    },
  },
});
