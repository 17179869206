export const RULES = Object.freeze({
  ALLOW_ALL: "allow_all",
  // dashboard
  DASHBOARD_VIEW: "dashboard_view", // view dashboard
  // users
  USERS_VIEW: "users_view", // view users page
  USER_EDIT: "user_edit",
  USER_DELETE: "user_delete",
  // teams
  TEAMS_VIEW: "teams_view", // view teams page
  TEAM_DELETE: "team_delete", // delete whole team
  TEAM_EDIT: "team_edit", // edit team info (name, etc)
  TEAM_ADD: "team_add", // add new team
  TEAM_ADD_USER: "team_add_user", // add new user to existing team
  TEAM_DELETE_USER: "team_delete_user", // delete user from a team
  TEAM_VIEW_JOBS: "team_view_jobs", // for displaying team jobs_and_tasks on dashboard
  // clients and accounts
  CLIENTS_VIEW: "clients_view", // view clients page
  CLIENT_ADD: "client_add",
  CLIENT_EDIT: "client_edit",
  CLIENT_DELETE: "client_delete",
  ACCOUNT_ADD: "account_add",
  ACCOUNT_EDIT: "account_edit",
  ACCOUNT_DELETE: "account_delete",
  // jobs_and_tasks
  JOBS_VIEW: "jobs_view", // view jobs_and_tasks page
  JOB_EDIT: "job_edit", // edit existing job
  JOB_DELETE: "job_delete", // delete job
  JOB_ADD: "job_add", // create new job
  JOB_ADD_SUBSCRIPTION: "job_subscription_add",
  JOB_DELETE_SUBSCRIPTION: "job_subscription_delete",
  JOB_EDIT_SUBSCRIPTION: "job_subscription_edit",
  TASK_EDIT: "task_edit",
  TASK_DELETE: "task_delete",
  TASK_ADD: "task_add",
  // platforms
  PLATFORMS_VIEW: "platform_view", // view platforms page,
  PLATFORM_ADD: "platform_add", // add new platform,
  PLATFORM_EDIT: "platform_edit", // edit platform
  PLATFORM_DELETE: "platform_delete", // delete platform
  // secrets
  SECRETS_VIEW: "secret_view", // view secrets page
  SECRET_ADD: "secret_add", // add secret
  SECRET_EDIT: "secret_edit", // edit secret
  SECRET_DELETE: "secret_delete", // delete secret
  // scripts
  SCRIPTS_VIEW: "script_view", // view scripts page
  SCRIPT_ADD: "script_add",
  SCRIPT_EDIT: "script_edit",
  SCRIPT_DELETE: "script_delete",
  ADMIN_VIEW: "admin_view",
});

export const ROLES = Object.freeze({
  ADMIN: "Admin",
  TEAM_LEADER: "Team leader",
  TEAM_MEMBER: "Team member",
  ACCOUNT: "Account",
  SUBSCRIBER: "Subscriber",
});

// is admin
export const is_admin = (auth_user) => auth_user.user.is_superuser;

// is admin or team_leader of the team
export const is_admin_leader = (auth_user, leaders) =>
  is_admin(auth_user) ||
  (leaders && leaders.includes(auth_user.user.id)) ||
  (auth_user.is_team_leader && leaders && leaders.length === 0);

// is admin or team_leader of the user's team or the user themselves (e.g. user, or job or secret author, etc.)
export const is_admin_leader_user = (auth_user, subjects) =>
  is_admin_leader(auth_user, subjects.leaders) ||
  auth_user.user.id === subjects.user.id;
