import { ref, computed } from "vue";
import router from "@/router";
import ConfirmationDialog from "@/common/forms/ConfirmationDialog.vue";
import { AlertsStore } from "@/stores/alerts";

const EMPTY_DIALOG_KEY = "component-in-waiting";

const ENTITY = Object.freeze({
  USER: "user",
  TEAM: "team",
  TEAM_MEMBER: "team member",
  SECRET: "secret",
  SCRIPT: "script",
  PLATFORM: "platform",
  JOB: "job",
  CLIENT: "client",
  ACCOUNT: "account",
  ACCESS: "access",
  TASK: "task",
  SCHEDULE: "schedule of job",
  CONFIG: "config of job",
  USER_JOBS: "jobs of user",
});

const ACTION = Object.freeze({
  ADD: "create",
  EDIT: "update",
  DELETE: "delete",
  PAUSE: "pause",
  UNPAUSE: "unpause",
  RUN_NOW: "one-off run of",
  SUBSCRIBE: "activate notification for",
  UNSUBSCRIBE: "deactivate notification for",
  TRANSFER: "transfer",
});

const is_open = ref(false); // toggle for dialog
const dialog_type = ref(null); // control for which component to insert into body slot
const dialog_context = ref(null); // data of the entity to be modified
const dialog_title = ref(""); // slot title
const dialog_callback = ref(null); // method to be called on submit
const dialog_validity = ref(false); // overall validity of the entire form
const dialog_redirect_to_path = ref(null);
const is_loading = ref(false);

export function handle_dialog() {
  function open(type, item, title, callback, path_to_push = null) {
    dialog_context.value = item; // remember the item to be modified
    dialog_type.value = type;
    dialog_title.value = title;
    dialog_callback.value = callback;
    is_open.value = true;
    is_loading.value = false;
    dialog_redirect_to_path.value = path_to_push;
    if (dialog_type.value === ConfirmationDialog) {
      validity(true);
    }
  }

  function close() {
    is_open.value = false;
    is_loading.value = false;
    dialog_type.value = null;
    dialog_context.value = null;
    dialog_title.value = "";
    dialog_callback.value = null;
    dialog_validity.value = false;
    dialog_redirect_to_path.value = null;
  }
  function validity(value) {
    dialog_validity.value = value;
  }
  async function submit(dialog) {
    is_loading.value = true;
    let alert_store = new AlertsStore();
    if (dialog_type.value !== ConfirmationDialog) {
      //  if adding/editing, the input must be validated
      if (dialog_validity.value) {
        await dialog_callback.value(dialog.form);
      }
    } else {
      await dialog_callback.value(dialog_context.value.item);
      if (dialog_redirect_to_path.value !== null) {
        await router.push(dialog_redirect_to_path.value);
        alert_store.set_messages_visibility(true);
      }
    }
    close();
  }

  return {
    open,
    close,
    submit,
    validity,
    EMPTY_DIALOG_KEY,
    is_open: computed(() => is_open.value),
    is_loading: computed(() => is_loading.value),
    dialog_type: computed(() => dialog_type.value),
    dialog_context: computed(() => dialog_context.value),
    dialog_title: computed(() => dialog_title.value),
    dialog_callback: computed(() => dialog_callback.value),
    dialog_validity: computed(() => dialog_validity.value),
  };
}

export function handle_message() {
  function create_message(
    action,
    entity,
    item = null,
    from_entity = null,
    from_item = null
  ) {
    let message = `${action} ${entity} ${item ? item.name || item.id : ""}`;
    message +=
      from_entity && from_item
        ? ` from ${from_entity} ${from_item.name || from_item.id}`
        : "";
    return message.charAt(0).toUpperCase() + message.slice(1); //capitalize
  }
  return {
    create_message,
    ENTITY,
    ACTION,
  };
}
