<template>
  <v-dialog
    v-model="opened"
    :width="width"
    :persistent="is_loading"
    :retain-focus="false"
  >
    <v-card class="pa-3" color="background_secondary">
      <v-card-title class="text-h7">
        <slot name="modal-title">
          {{ dialog_title }}
        </slot>
      </v-card-title>
      <v-card-text>
        <v-expand-transition>
          <div v-if="!is_loading">
            <slot name="modal-body" />
          </div>
        </v-expand-transition>
        <div class="d-flex justify-center pa-12" v-if="is_loading">
          <v-progress-circular :size="100" color="primary" indeterminate />
        </div>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn
          v-if="!is_loading"
          rounded
          outlined
          color="outline"
          @click="opened = false"
        >
          cancel
          <v-icon small>mdi-close-circle</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          rounded
          outlined
          color="success"
          @click="$emit('submit')"
          :disabled="!dialog_validity"
          v-if="!is_loading"
        >
          confirm
          <v-icon small>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { handle_dialog } from "@/composables/dialogs";
export default {
  name: "BaseFormDialog",
  props: {
    width: { type: Number, default: 500 },
    is_open: { type: Boolean, default: false },
  },
  setup() {
    const { dialog_title, dialog_validity, is_loading } = handle_dialog();
    return { dialog_title, dialog_validity, is_loading };
  },
  computed: {
    opened: {
      get() {
        return this.is_open;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style scoped></style>
