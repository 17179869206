export const ROUTES = Object.freeze({
  BASE_URL: process.env.VUE_APP_API_URL,
  HOMEPAGE: "/",
  DASHBOARD: "dashboard",
  API: "api",
  REDUCED: "reduced",
  AUTH: "auth",
  USERS: "users",
  PLATFORMS: "platforms",
  CLIENTS: "clients",
  CLIENT_TYPES: "client-types",
  ACCOUNTS: "accounts",
  SCRIPTS: "scripts",
  SECRETS: "secrets",
  TEAMS: "teams",
  LOGIN: "login",
  ACCESSES: "accesses",
  TASKS: "tasks",
  JOBS: "jobs",
  JOB_RUNS: "job-runs",
  JOB_RESULTS: "job-results",
  JOB_SUBSCRIBED_RESULTS: "job-subscribed-results",
  JOB_SUBSCRIPTIONS: "job-subscriptions",
  JOB_DELETE_SCHEDULE: "delete-schedule",
  ALLOWED: "allowed",
  NAME_VALIDATE: "validate",
  ACCOUNT_NAME_VALIDATE: "validate-account-name",
  ACCOUNT_PLATFORM_ID_VALIDATE: "validate-account-platform-id",
});

export const METHODS = Object.freeze({
  POST: "post",
  GET: "get",
  DELETE: "delete",
  UPDATE: "update",
  PUT: "put",
  PATCH: "patch",
});

export const CONTROLLERS = Object.freeze({
  PAUSE: "pause",
  RESUME: "resume",
  RUN: "run",
});

export const STATUS = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
});
