<template>
  <div>
    <div v-show="visible">
      <v-alert
        dense
        outlined
        text
        :color="message.type === STATUS.SUCCESS ? 'success' : 'error'"
        :type="message.type"
        v-for="(message, index) in alert_messages"
        :key="`alert_message_${index}`"
      >
        {{ message.alert_message }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { AlertsStore } from "@/stores/alerts";
import { STATUS } from "@/utils/requests/routes";

export default {
  name: "AlertMessage",
  setup() {
    const alerts_store = AlertsStore();
    return { alerts_store };
  },
  props: ["number_of_messages"],
  data() {
    return {
      STATUS,
    };
  },
  computed: {
    alert_messages() {
      return this.number_of_messages
        ? this.alerts_store.get_alerts.slice(-this.number_of_messages)
        : [];
    },
    visible() {
      return this.alerts_store.messages_visibility;
    },
  },
};
</script>

<style scoped></style>
