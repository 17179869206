import { defineStore } from "pinia";
import { process_request } from "@/utils/requests";
import { METHODS, ROUTES } from "@/utils/requests/routes";
import { cron_to_human_readable } from "@/utils/time";

export const ScriptsStore = defineStore("scripts", {
  state: () => ({
    scripts: [],
    headers: [
      { text: "name", value: "name", width: "250px" },
      { text: "description", value: "description", width: "200px" },
    ],
  }),
  getters: {
    get_script: (state) => {
      return (script_id) =>
        script_id ? state.scripts.find((script) => script.id == script_id) : {};
    },
    get_script_by_short_name: (state) => {
      return (short_name) =>
        short_name
          ? state.scripts.find((script) => script.short_name === short_name)
          : {};
    },
    get_task_enabled_scripts() {
      return this.scripts.filter((script) =>
        "task_creation_enabled" in script.config
          ? script.config.task_creation_enabled
          : true
      );
    },
  },
  actions: {
    async fetch_scripts() {
      let response = await process_request(
        METHODS.GET,
        `${ROUTES.API}/${ROUTES.SCRIPTS}/`
      );
      response.data.forEach((script) => {
        if (script.config?.time_of_run) {
          script.config.time_of_run_string = cron_to_human_readable(
            script.config.time_of_run
          );
        }
        return script;
      });
      this.scripts = response.data;
      this.scripts.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
});
