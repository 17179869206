import { ROLES } from "../permissions";

export function get_roles(data) {
  let roles = [];
  if (data.is_superuser) {
    roles.push(ROLES.ADMIN);
  }
  if (data.is_team_leader) {
    roles.push(ROLES.TEAM_LEADER);
  } else if (data.team != null) {
    roles.push(ROLES.TEAM_MEMBER);
  }
  return roles;
}
