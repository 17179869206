import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
  customProperties: true,
  theme: {
    options: {
      // themeCache: {
      //     get: key => localStorage.getItem(key),
      //     set: (key, value) => localStorage.setItem(key, value),
      // },
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#a620ba",
        secondary: colors.shades.black,
        accent: colors.shades.black,
        error: "#c60127",
        warning: colors.amber.darken3,
        background: "#F1F0F1",
        background_secondary: colors.shades.white,
        outline: colors.shades.black,
        anchor: "#2176ff",
        links: "#2176ff",
        darken1: "#a6a4a6",
        pink: "#F06292",
        red_light: "#EF5350",
        red_dark: "#B71C1C",
      },
      dark: {
        primary: "#f7941d",
        secondary: colors.shades.white,
        accent: colors.shades.black,
        error: "#c60127",
        warning: colors.amber.darken4,
        background: "#1D0D1B",
        background_secondary: "#2B1C29",
        outline: colors.shades.white,
        anchor: "#2176ff",
        links: "#2176ff",
        darken1: "#63325d",
        pink: "#F06292",
        red_light: "#EF5350",
        red_dark: "#B71C1C",
      },
    },
  },
};

export default new Vuetify(opts);
