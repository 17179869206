import { defineStore } from "pinia";

export const AlertsStore = defineStore("alerts", {
  state: () => ({
    alerts: [],
    messages_visibility: false,
    retain_visibility: false,
  }),
  getters: {
    get_alerts() {
      return this.alerts;
    },
  },
  actions: {
    add_alert(status, message, time = null) {
      this.alerts.push({
        type: status,
        time: time || Date.now(),
        alert_message: message,
      });
      this.set_messages_visibility(true);
    },
    set_messages_visibility(visibility) {
      this.messages_visibility = visibility;
    },
    set_retain_visibility(visibility) {
      this.retain_visibility = visibility;
    },
  },
});
